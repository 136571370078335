import { LoginContext } from '@/context/login'
import { useContext, useState } from 'react'
import Input from '@/components/ui/Input'
import Account from '@/components/ui/Account'
import Button from '@/components/ui/Button'
import { useForm } from 'react-hook-form'
import { faEnvelope, faTelegram } from '@fortawesome/pro-duotone-svg-icons'
import { faLandmark, faUser } from '@fortawesome/pro-solid-svg-icons'
import AuthService from '@/services/auth'
import AuthCode from '@/components/auth/AuthCode'
import Alert from '@/components/ui/Alert'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function InitLogin() {
	const [, loginDispatch] = useContext(LoginContext)
	const [loading, setLoading] = useState(false)
	const [accountType, setAccountType] = useState('INDIVIDUAL')
	const [error, setError] = useState(null)
	const [check, setCheck] = useState(false)
	const { executeRecaptcha } = useGoogleReCaptcha()
	const { register, handleSubmit, errors } = useForm()

	const onSubmit = async (data) => {
		setError(null)
		setLoading(true)

		if (check === false) {
			setLoading(false)
			setError('Please accept terms & conditions')
			return
		}

		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}

		const body = {
			email: data.email,
			telegram: data.telegram,
			wallet: data.wallet,
			twitter: data.twitter,
			discord: data.discord,
			pledge: data.pledge,
			type: accountType,
		}

		// console.log(body)

		await AuthService.Signup(body, config)
			.then(() => {
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							email: body.email,
							code: '',
							account_type: accountType,
						},
						currentComponent: <AuthCode></AuthCode>,
					},
				})
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	const formSchema = {
		email: {
			required: 'Email required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email',
			},
		},
		wallet: {
			required: 'Wallet required',
		},
		telegram: {},
		discord: {
			required: 'Discord required',
		},
		twitter: {
			required: 'Twitter required',
		},
		pledge: {
			required: 'Pledge required',
		},
	}

	return (
		<>
			{error ? <Alert type='error' title={error}></Alert> : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex justify-between mb-4">
					<div className="w-1/2 pr-2">
						<Account
							full
							value={accountType}
							account='INDIVIDUAL'
							icon={faUser}
							label='Individual'
							onClick={() => setAccountType('INDIVIDUAL')}
						/>
					</div>
					<div className="w-1/2 pl-2">
						<Account
							full
							onClick={() => setAccountType('CORPORATE')}
							icon={faLandmark}
							account='CORPORATE'
							value={accountType}
							label='Corporate'
						/>
					</div>
				</div>
				<div className='mb-5'>
					<Input
						icon={faEnvelope}
						label='Email'
						referrer={register(formSchema.email)}
						placeholder='john.doe@synaps.io'
						name='email'
						error={errors.email}
						message={errors.email?.message}
					/>
				</div>
				<div className='mb-5'>
					<Input
						// icon={faTelegram}
						label='Public address'
						referrer={register(formSchema.wallet)}
						placeholder='0x'
						name='wallet'
						error={errors.wallet}
						message={errors.wallet?.message}
					/>
				</div>
				<div className='mb-5'>
					<Input
						// icon={faTelegram}
						label='Telegram account (optional)'
						referrer={register(formSchema.telegram)}
						placeholder='@yyyy'
						name='telegram'
						error={errors.telegram}
						message={errors.telegram?.message}
					/>
				</div>
				<div className='mb-5'>
					<Input
						// icon={faTelegram}
						label='Discord handle'
						referrer={register(formSchema.discord)}
						placeholder='xxxx#yyyy'
						name='discord'
						error={errors.discord}
						message={errors.discord?.message}
					/>
				</div>
				<div className='mb-5'>
					<Input
						// icon={faTelegram}
						label='URL Twitter'
						referrer={register(formSchema.twitter)}
						placeholder='Like + React to the pinned tweet and tag 2 friends'
						name='twitter'
						error={errors.twitter}
						message={errors.twitter?.message}
					/>
				</div>
				<div className='mb-5'>
					<Input
						// icon={faTelegram}
						label='Pledge (in BNB)'
						referrer={register(formSchema.pledge)}
						placeholder='[Max : 10BNB]'
						name='pledge'
						error={errors.pledge}
						message={errors.pledge?.message}
					/>
				</div>
				<div className='mb-5'>
					<div className="flex">
						<input className="checkbox mr-3 ml-2 mt-1"
							name="isGoing"
							type="checkbox"
							checked={check}
							onChange={() => setCheck(!check)} />
						<a href="https://storage.googleapis.com/synaps-media/MyTVChain%20-%20TCs%20ICO%20-%20English%20version%20-%20V2.docx.pdf" target="_blank" rel="noreferrer"><p className="underline block text-sm mb-3 font-medium text-primary-700">terms & conditions</p></a>

					</div>
				</div>
				<div>
					<Button
						type='submit'
						full
						loading={loading}
						label='Sign Up'
					/>
				</div>
			</form>
		</>
	)
}
